
.NavigationWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.CloseWrapper {
    width: min-content;
    padding: 8px 0;
    font-size: 18px;
    cursor: pointer;
    display: flex;
}

.CloseWrapper span {
    padding-top: 3px;
    margin-right: 8px;
}

.ScanCountArea {
    padding: 16px 8px 0 8px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: min-content;
}

.ScanCountArea input {
    border-radius: 8px;
    outline: none;
    border: 1px solid black;
    padding: 0 0 0 8px;
}

.ScanCountArea button.Button {
    width: auto;
    font-size: 10px;
}