.HeaderWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;
    column-gap: 8px;
}

.HeaderInput {
    width: 100%;
    max-width: 300px;
}

.HeaderInput input {
    width: calc(100% - 10px);
    border-radius: 6px;
    font-size: 18px;
    border: 2px solid grey;
    outline: none;
    padding: 4px;
}

.HeaderInput input:focus {
    box-shadow: 0 0 16px 0px rgba(0,0,0,0.1);
}

.PrinterSelect {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    column-gap: 2px;
}

.PrinterSelect select {
    width: stretch;
    padding: 4px;
    outline: none;
    border: 2px solid grey;
    border-radius: 6px;
}

.PrinterSelect select option {
    padding: 4px 0;
}

.BinDisplay {
    width: calc(100% - 18px);
    background: white;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 24px;
    text-align: right;
    border: 1px solid black;
}

.bin {
    width: calc(100% - 18px);
    background: #e2b777 !important;
}

.ForcePrint {
    width: calc(100% - 18px);
}


@media screen and (min-width: 1200px) {
    .HeaderWrapper {
        flex-direction: row;
        row-gap: 8px;
    }

    .HeaderInput {
        width: 50%;
    }

    .bin {
        width: 100px;
    }

    .ForcePrint {
        width: 82px;
    }

    .BinDisplay {
        width: 20%;
        background: white;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 24px;
        text-align: right;
        border: 1px solid black;
    }
}