

.InputWrapper {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    min-height: 62px;
}

.InputWrapper label {
    font-size: 18px;
    margin-bottom: 2px;
}

.InputWrapper input {
    padding: 6px 8px;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid black;
    outline: none;
}