
.MainComponent {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.ContentWrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

.ContentContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
    width: 97%;
}


@media screen and (min-width: 800px) {
    .ContentContainer {
        width: 95%;
    }
}

.FormColumn {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.FormRow {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}

.ArtTypeDescription {
    font-size: 12px;
    padding: 10px 0;
}

.FitArea {
    width: 50%;
}