
.OrderDisplayWrapper {
    width: 100%;
    background: #f7f7f7;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 0 16px rgba(0,0,0,0.15);
}

.OrderDisplayContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.OrderDisplayTopSection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 60% 1fr;
}

.OrderDisplayTopLeft {
    display: flex;
    flex-direction: column;
}

.OrderDisplayTopCenter {
    max-height: 100px;
    display: flex;
    justify-content: center;
}

.OrderDisplayTopCenter img {
    max-width: 150px;
}

.OrderDisplayTopRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: bold;
}

.OrderNumber {
    font-size: 32px;
    font-weight: bold;
}

.OrderDate {
    font-size: 16px;
}

.OrderStatus {
    font-size: 18px;
}

.OrderList {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-radius: 8px;
}

