
.ModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.15);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    
}

.ModalContainer {
    width: 400px;
    padding: 16px;
    background: white;
    box-shadow: 0 0 16px rgba(0,0,0,0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}