.InventoryItemWrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;
    padding-top: 30px;
}

.InventoryItem {
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    justify-content: center;
}

.InventoryActions {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.AdditionalDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;

}

.DetailsSection {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
}

.ButtonSection {
    justify-content: flex-end;
    width: min-content;
}

.Image {
    max-width: 200px;
}

.Image img {
    width: 200px;
}

.InventoryItemWrapper .Information {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    font-size: 25px;
}

.InventoryItemWrapper .InfoItem {
    width: 100%;
    min-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ModalContent {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.ModalContent span {
    font-size: 18px;
    font-weight: bold;
}

.ModalContent input {
    padding: 6px 8px;
    border-radius: 6px;
    font-size: 18px;
    outline: none;
    border: 1px solid black;
}