
table {
    overflow: hidden;
    overflow-x: scroll;
    font-size: 11px;
    font-weight: bold;
    border-spacing: 0;
}

table thead tr {
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;    
}

table thead tr th {
    width: stretch;
    text-align: left;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 15px;
    padding: 6px 0;
}

table thead tr th span {
    padding: 0 0 0 13px;
}

table thead tr th:last-child {
    border-right: none;
}

table tbody tr {
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
}

table tbody tr:last:child {
    border-bottom: none;
}

table tbody tr td {
    height: 75px;
    width: stretch;
    text-align: center;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

table tbody tr td:last-child {
    border-right: none;
}

table tbody tr td img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

table tbody tr.Scanned {
    background: #e2b777;
}