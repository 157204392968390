

.InventoryWrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.InventoryPagesHeaders {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
}

.InventoryHeaderWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    align-content: center;
    flex-wrap: wrap;
}

.HeaderItem {
    font-size: 14px;
    padding: 6px 8px;
    cursor: pointer;
    background: #ececec;
    border-radius: 4px;
}

.HeaderItem:hover {
    background: #dfdfdf;
}