.Button {
    width: calc(100%);
    padding: 8px 16px;
    border: 2px solid #b98873;
    color: black;
    background: #e2b777;
    font-size: 20px;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
}

.Button:hover {
    background: #c59e63;
}

.Button:disabled {
    background: #d4d4d4;
    border: 2px solid #bbbbbb;
}