.SnackbarWrapper {
    position: fixed;
    top: -100px;
    right: 100px;
    transition: top ease-out 0.2s;
    z-index: 10;
    background: white;
    border: 2px solid black;
    border-radius: 8px;
    font-size: 18px;
    text-align: center;
    padding: 10px 20px;
}

