.RoutesWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}

.RouterHeader {
    height: 75px;
    width: 100%;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    background: #f7f7f7;
    display: grid;
    border-bottom: 1px solid black;
    grid-template-columns: 1fr 300px 1fr;
}

.Routes {
    height: calc(100% - 75px - 8px);
    width: 100%;
    display: flex;
    padding: 8px 0 0 0;
}

.ScanalyticsWindow {
    width: 399px;
    position: fixed;
    height: 100vh;
    overflow: hidden;
    transition: right ease-out 0.3s;
    border-left: 1px solid black;
    background: #f7f7f7;
}

.ScanalyticsInner {
    height: calc(100% - 32px);
    overflow: auto;
    width: calc(100% - 32px);
    padding: 32px 16px 0 16px;
}

.WallblushImage {
    height: 75px;
    width: 100%;
}

.WallblushImage img {
    height: 100%;
    margin: auto;
}

.UserArea {
    padding: 16px 8px 0 8px;
    display: flex;
    justify-content: flex-end;
}

.ScanCountArea {
    padding: 16px 8px 0 8px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: min-content;
}

.ScanCountArea input {
    border-radius: 8px;
    outline: none;
    border: 1px solid black;
    padding: 0 0 0 8px;
}

.ScanCountArea button.Button {
    width: auto;
    font-size: 10px;
}

.Username {
    padding: 6px 16px;
    background: #e2b777;
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
    text-align: right;
    display: block;
    height: min-content;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid black;
}

.hamburger {
    display: block;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.Scans {
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    display: flex;
    width: 100%;
}