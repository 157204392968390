.TableWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Icons {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
}

.ScannedIcon {
    padding: 0 20px;
}

.SplitId {
    text-decoration: underline;
    padding: 30px 0;
    cursor: pointer;
    font-size: 16px;
}

.Standard {
    padding: 30px 0;
    font-size: 16px;
}

.CheckedIcon {
    width: 32px !important;
    height: 32px !important;
    border: 4px solid white;
    border-right: 4px solid white !important;
    background: transparent;
    border-radius: 4px;
    position: relative;

}

.CheckedIcon::after {
    content: '';
    display: inline-block;
    transform: rotate(37deg);
    height: 18px;
    width: 10px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    position: absolute;
    top: 2px;
    left: 9px;
}

.UncheckedIcon {
    width: 32px !important;
    height: 32px !important;
    border: 4px solid #b98873;
    border-right: 4px solid #b98873 !important;
    background: white;
    border-radius: 4px;
}

.ModalContent {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.ModalContent hr {
    width: 100%;
}