
.NavItemWrapper {
    width: calc(100% - 8px);
    height: calc(50px - 32px);
    padding: 14px 0 18px 8px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 1px solid black;
    background: #f7f7f7;
}

.NavItemWrapper:hover {
    background: #dfdfdf;
}

.IsFocused {
    background: #dfdfdf !important;
}