.LoginWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.LoginBubble {
    height: min-content;
    background: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    width: calc(400px - 32px);
    padding: 16px;
    border: 1px solid black;
    row-gap: 20px;
}

.InputWrapper {
    width: 100%;
}

.Label {
    width: 100%;
    font-size: 16px;
}

.Input {
    width: calc(100% - 8px);
    margin: 8px 0;
    outline: none;
    padding: 4px;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid black;
}

.ErrorMessage {
    color: rgb(228, 101, 101);
}